import React, { useState, useEffect } from 'react';
import confetti from 'canvas-confetti';
import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogTitle, AlertDialogTrigger } from '../components/ui/alert-dialog';
import { Button } from '../components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import { ThumbsUp, ThumbsDown, BarChart2, Trash2, Download } from 'lucide-react';
import SVGRulerLoader from './SVGRulerLoader';

const API_URL = 'https://api.1ft.io/api'; // API
// const API_URL = 'http://localhost:4579/api'; // API


const RainEffect = ({ duration = 3000 }) => {
  const canvasRef = React.useRef(null);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const raindrops = [];

    class Raindrop {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = -10;
        this.speed = Math.random() * 5 + 2;
        this.length = Math.random() * 20 + 10;
      }

      fall() {
        this.y += this.speed;
        if (this.y > canvas.height) {
          this.y = -10;
          this.x = Math.random() * canvas.width;
        }
      }

      draw() {
        ctx.beginPath();
        ctx.moveTo(this.x, this.y);
        ctx.lineTo(this.x, this.y + this.length);
        ctx.strokeStyle = 'rgba(0, 51, 102, 0.5)';
        ctx.lineWidth = 1;
        ctx.stroke();
      }
    }

    for (let i = 0; i < 100; i++) {
      raindrops.push(new Raindrop());
    }

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      raindrops.forEach((drop) => {
        drop.fall();
        drop.draw();
      });
      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    const timeout = setTimeout(() => {
      cancelAnimationFrame(animationFrameId);
    }, duration);

    return () => {
      cancelAnimationFrame(animationFrameId);
      clearTimeout(timeout);
    };
  }, [duration]);

  return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, pointerEvents: 'none' }} />;
};

async function getLocation() {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    return {
      ip: data.ip,
      lat: data.latitude,
      lon: data.longitude,
      city: data.city,
      country: data.country_name
    };
  } catch (error) {
    console.error('Error fetching location:', error);
    return {
      ip: 'Unknown',
      lat: null,
      lon: null,
      city: 'Unknown',
      country: 'Unknown'
    };
  }
}

const FeedbackCounter = () => {
  const [showQuestion, setShowQuestion] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);
  const [isPositive, setIsPositive] = useState(false);
  const [voteCount, setVoteCount] = useState({ yes: 0, no: 0 });
  const [showVoteLog, setShowVoteLog] = useState(false);
  const [voteLog, setVoteLog] = useState([]);
  const [showRain, setShowRain] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userFingerprint, setUserFingerprint] = useState('');
  const [showClearConfirmation, setShowClearConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const fingerprint = generateFingerprint();
        setUserFingerprint(fingerprint);
        await checkIfUserVoted(fingerprint);
        await checkAdminAccess();
        await fetchVoteCounts();
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setIsLoading(false);
        // Add a delay before hiding the loader to ensure it completes its animation
        setTimeout(() => setShowLoader(false), 1000); // Adjust the delay as needed
      }
    };

    fetchInitialData();
  }, []);

  const generateFingerprint = () => {
    const { userAgent, language, hardwareConcurrency, deviceMemory } = navigator;
    const { colorDepth, pixelDepth, width, height } = window.screen;
    const timezoneOffset = new Date().getTimezoneOffset();
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl');
    const debugInfo = gl ? gl.getExtension('WEBGL_debug_renderer_info') : null;
    const renderer = debugInfo ? gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) : null;

    return btoa(`${userAgent}|${language}|${hardwareConcurrency}|${deviceMemory}|${colorDepth}|${pixelDepth}|${width}x${height}|${timezoneOffset}|${renderer}`);
  };

  const checkIfUserVoted = async (fingerprint) => {
    try {
      const response = await fetch(`${API_URL}/check-vote/${fingerprint}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setShowQuestion(!data.hasVoted);
    } catch (error) {
      console.error('Error checking if user voted:', error);
      setShowQuestion(true); // Assume user hasn't voted if there's an error
    }
  };

  const checkAdminAccess = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const pass = urlParams.get('pass');
    if (pass === '123') {
      setIsAdmin(true);
      console.log('Admin access granted');
    }
  };

  const fetchVoteCounts = async () => {
    try {
      const response = await fetch(`${API_URL}/vote-counts`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setVoteCount(data);
    } catch (error) {
      console.error('Error fetching vote counts:', error);
    }
  };

  const handleFeedback = async (positive) => {
    console.log('Handle feedback called, positive:', positive);

    try {
      const locationData = await getLocation();
      const response = await fetch(`${API_URL}/vote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userFingerprint,
          vote: positive ? 'Yes' : 'No',
          timestamp: new Date().toISOString(),
          userAgent: navigator.userAgent,
          language: navigator.language,
          screenResolution: `${window.screen.width}x${window.screen.height}`,
          colorDepth: window.screen.colorDepth,
          pixelDepth: window.screen.pixelDepth,
          timezoneOffset: new Date().getTimezoneOffset(),
          hardwareConcurrency: navigator.hardwareConcurrency,
          deviceMemory: navigator.deviceMemory,
          ipAddress: locationData.ip,
          latitude: locationData.lat,
          longitude: locationData.lon,
          city: locationData.city,
          country: locationData.country
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();

      if (data.success) {
        setShowQuestion(false);
        setShowThankYou(true);
        setIsPositive(positive);
        fetchVoteCounts(); // Refresh vote counts

        if (positive) {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
          });
        } else {
          setShowRain(true);
          setTimeout(() => setShowRain(false), 3000);
        }
      } else {
        console.error('Vote submission failed:', data.message);
        alert(data.message || "You have already voted. Thank you for your feedback!");
        setShowQuestion(false);
      }
    } catch (error) {
      console.error('Error submitting vote:', error.message);
      alert(`An error occurred while submitting your vote: ${error.message}. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleVoteLog = async () => {
    if (!showVoteLog) {
      try {
        const response = await fetch(`${API_URL}/vote-log`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setVoteLog(data);
      } catch (error) {
        console.error('Error fetching vote log:', error);
        alert('Failed to fetch vote log. Please try again.');
      }
    }
    setShowVoteLog(!showVoteLog);
  };

  const clearAllData = async () => {
    try {
      const response = await fetch(`${API_URL}/clear-data`, { method: 'POST' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.success) {
        setVoteCount({ yes: 0, no: 0 });
        setVoteLog([]);
        setShowQuestion(true);
        setShowThankYou(false);
        setIsPositive(false);
        console.log('All data cleared');
        checkIfUserVoted(userFingerprint);
      } else {
        throw new Error('Failed to clear data');
      }
    } catch (error) {
      console.error('Error clearing data:', error);
      alert('An error occurred while clearing data. Please try again.');
    }
  };

  const downloadLogs = async () => {
    try {
      const response = await fetch(`${API_URL}/download-logs`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'vote_logs.json';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading logs:', error);
      alert('An error occurred while downloading logs. Please try again.');
    }
  };

  const handleClearDataClick = () => {
    setShowClearConfirmation(true);
  };

  const handleConfirmClear = async () => {
    try {
      const response = await fetch(`${API_URL}/clear-data`, { method: 'POST' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.success) {
        setVoteCount({ yes: 0, no: 0 });
        setVoteLog([]);
        setShowQuestion(true);
        setShowThankYou(false);
        setIsPositive(false);
        console.log('All data cleared');
        checkIfUserVoted(userFingerprint);
      } else {
        throw new Error('Failed to clear data');
      }
    } catch (error) {
      console.error('Error clearing data:', error);
      alert('An error occurred while clearing data. Please try again.');
    } finally {
      setShowClearConfirmation(false);
    }
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-r from-blue-100 to-purple-100 p-4 overflow-hidden">
      {showLoader && (
        <div className="absolute inset-0 flex justify-center items-center bg-gradient-to-r from-blue-100 to-purple-100 z-50">
          <SVGRulerLoader />
        </div>
      )}
      <div className={`transition-opacity duration-500 ${showLoader ? 'opacity-0' : 'opacity-100'}`}>
        <div className="absolute -left-20 -top-15">
          <img
            src="fav.png"
            alt="OG"
            style={{ width: '150px', transform: 'rotate(45deg)' }}
            className="select-none pointer-events-none"
          />
        </div>
        <div className="flex flex-col items-center justify-center min-h-screen">
          <Card className="w-full max-w-md">
            <CardHeader>
              <div className="flex justify-center mt-4">
                <img
                  src="bala.png"
                  alt="Bala Boy"
                  style={{ width: '150px' }}
                />
              </div>
              <CardTitle className="text-2xl font-bold text-center">Feedback</CardTitle>
              <CardDescription className="text-center">Your opinion matters to us!</CardDescription>
            </CardHeader>
            <CardContent>
              {showQuestion ? (
                <div className="text-center">
                  <h2 className="text-xl font-semibold mb-4">Should we start this service back up?</h2>
                  <div className="flex justify-center space-x-4">
                    <Button onClick={() => handleFeedback(true)} className="bg-green-500 hover:bg-green-600">
                      <ThumbsUp className="mr-2 h-4 w-4" /> Yes
                    </Button>
                    <Button onClick={() => handleFeedback(false)} className="bg-red-500 hover:bg-red-600">
                      <ThumbsDown className="mr-2 h-4 w-4" /> No
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <h2 className="text-xl font-semibold mb-4">Thank you for your feedback!</h2>
                  <p>You have already voted. Each user can only vote once.</p>
                </div>
              )}
            </CardContent>
            <CardFooter className="flex justify-between">
              {isAdmin && (
                <div className="text-sm text-gray-500">
                  Yes: {voteCount.yes} | No: {voteCount.no}
                </div>
              )}
              {isAdmin && (
                <Button variant="outline" size="sm" onClick={toggleVoteLog}>
                  <BarChart2 className="mr-2 h-4 w-4" />
                  {showVoteLog ? 'Hide Log' : 'Show Log'}
                </Button>
              )}
            </CardFooter>
          </Card>
  
          <AlertDialog open={showThankYou} onOpenChange={setShowThankYou}>
            <AlertDialogContent>
              <AlertDialogTitle>{isPositive ? 'Thank you for your positive feedback!' : 'Thank you for your feedback'}</AlertDialogTitle>
              <AlertDialogDescription>
                {isPositive
                  ? 'We appreciate your support and will work hard to meet your expectations.'
                  : 'We value your input and will use it to improve our services.'}
              </AlertDialogDescription>
            </AlertDialogContent>
          </AlertDialog>
  
          {isAdmin && showVoteLog && (
            <Card className="mt-4 w-full max-w-2xl">
              <CardHeader>
                <CardTitle>Vote Log</CardTitle>
              </CardHeader>
              <CardContent className="max-h-96 overflow-auto">
                <ul className="space-y-2">
                  {voteLog.map((vote, index) => (
                    <li key={index} className="bg-white p-2 rounded shadow">
                      <p><strong>Vote:</strong> {vote.vote}</p>
                      <p><strong>Timestamp:</strong> {vote.timestamp}</p>
                      <p><strong>User Agent:</strong> {vote.userAgent}</p>
                      <p><strong>Screen Resolution:</strong> {vote.screenResolution}</p>
                      <p><strong>IP Address:</strong> {vote.ipAddress}</p>
                      <p><strong>Location:</strong> {vote.city}, {vote.country}</p>
                      <p><strong>Coordinates:</strong> {vote.latitude}, {vote.longitude}</p>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          )}
  
          {isAdmin && (
            <div className="mt-4 space-x-2">
              <AlertDialog open={showClearConfirmation} onOpenChange={setShowClearConfirmation}>
                <AlertDialogTrigger asChild>
                  <Button variant="destructive">
                    <Trash2 className="mr-2 h-4 w-4" /> Clear All Data
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogTitle>Are you sure you want to clear all data?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This action cannot be undone. All vote data will be permanently deleted.
                  </AlertDialogDescription>
                  <div className="mt-4 flex justify-end space-x-2">
                    <Button variant="outline" onClick={() => setShowClearConfirmation(false)}>Cancel</Button>
                    <Button variant="destructive" onClick={handleConfirmClear}>Confirm Clear</Button>
                  </div>
                </AlertDialogContent>
              </AlertDialog>
              <Button onClick={downloadLogs} variant="outline">
                <Download className="mr-2 h-4 w-4" /> Download Logs
              </Button>
            </div>
          )}
        </div>
      </div>
      {showRain && <RainEffect duration={5000} />}
    </div>
  );
};

export default FeedbackCounter;
