import React from 'react';
import FeedbackCounter from './components/FeedbackCounter';

function App() {
  return (
    <div className="App">
      <FeedbackCounter />
    </div>
  );
}

export default App;